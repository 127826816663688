import { css } from '@emotion/core';
import { BASE_COLOR } from './theme';
import slick from './slick';

const global = css`
  *::before,
  *::after,
  * {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
    overflow-y: initial; /* prevent "jumping" to the top of the page if body scroll is disabled */
  }

  body {
    padding: 0;
    background-color: #fffdfc;
    font-feature-settings: 'kern';
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  /* sticky footer */
  header,
  footer {
    flex: none;
  }

  #___gatsby > div {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  /* react-headroom styles */
  .headroom--scrolled {
    box-shadow: 0 -4px 5px 0 rgba(22, 11, 44, 0.1),
      0 4px 5px 0 rgba(22, 11, 44, 0.1);
  }

  /* react-modal stlyes */
  #___gatsby {
    transition: filter 0.15s linear;
  }

  .ReactModal__Body--open > #___gatsby {
    filter: blur(4px);
  }

  b,
  strong {
    font-weight: 700;
  }

  ::selection {
    color: #fff;
    background-color: ${BASE_COLOR};
  }

  ${slick}

  /* Customize workable jobs widget styling */
  .whr-date {
    display: none;
  }

  .whr-items,
  .whr-info {
    margin-left: 0;
    list-style: none;
  }

  table {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  #hubspotForm {
    ul, li {
      margin-bottom: 0;
      list-style: none;
      margin-left: 0;
    }
    .legal-consent-container p {
      font-size: 0.875rem;
      margin-bottom: 0;
      margin-top: .5rem;
    }
    input[type=text],
    input[type=email],
    input[type=tel],
    select {
      width: 100%;
      -webkit-appearance:none;
      padding: 8px 12px;
      border: 1px solid rgba(22, 11, 44, 0.8);
      border-radius: 6px;
      color: #160B2C;
      &:focus-visible {
        outline: #489F9D auto 1px;
      }
    }
    select {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSI3Ij48cGF0aCBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iIzE2MEIyQyIgZD0ibS44MDIuNDQgNiA2IDYtNiIvPjwvc3ZnPg==');
      background-repeat: no-repeat;
      background-position: right 13px center;
    }

    .field {
      margin-bottom: 1rem;
    }

    label {
      display: block;
      font-size: 14px;
      margin-left: 12px;
      margin-bottom: 3px;
      span {
        line-height: 1.2rem;
      }
    }

    .hs-fieldtype-file label {
      margin-left: 0;
    }

    .hs-error-msgs.inputs-list label {
      margin-top: 3px;
      font-size: 12px;
      color: #a23412;
    }
    .submitted-message p {
      color: #489F9D;
      font-size: 1.125rem;
    }
    input[type=submit] {
      position: relative;
      padding: 1rem 2.5rem;
      margin-top: 1rem;
      display: inline-block;
      max-width: 100%;
      border: 1px solid transparent;
      border-radius: 6px;
      font-family: Source Code Pro,SFMono,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace;
      box-Sizing: border-box;
      color: #fff;
      background-color: #489F9D;
      font-weight: 600;
      font-size: 0.875rem;
      letter-spacing: 1.5px;
      line-height: 1.285;
      text-align: 'center';
      -webkit-appearance:none;
      
      vertical-align: middle;
      text-decoration: none;
      text-transform: uppercase;
      white-space: nowrap;
      text-overflow: ellipsis,
      overflow: hidden;
      outline: 0;
      userSelect: none;
      cursor: pointer;
      transition: opacity 0.15s cubic-bezier(0.4, 0.1, 1, 1), box-shadow 0.15s cubic-bezier(0.4, 0.1, 1, 1);
    }
    input[type=file] {
      padding-top: 3px;
      width: 350px;
      max-width: 100%;
      margin-top: 0;
      font-weight: 600;
      line-height: 1.285;
    }
  }
`;

export default global;
